import React, { useEffect, useRef, useState } from 'react'
import useClickOutside from '~hooks/useClickOutside'

import { TSelectProps } from './Select.types'

import './Select.scss'

/*
 * Customizable select with standard <select> bahavior simulated.
 *
 * Note : Main button is always focused when using Select.
 */
const Select = ({
	value,
	onChange,
	options = [],
	theme = 'normal',
	buttonElement,
	optionElement,
	className,
	children,
	label = ''
}: TSelectProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const [currentFocusIndex, setCurrentFocusIndex] = useState(null)

	const rootRef = useRef<HTMLDivElement>()
	const buttonRef = useRef<HTMLButtonElement>()
	const dropdownRef = useRef<HTMLDivElement>()

	// Close the dropdown when click outside.
	useClickOutside(isOpen, setIsOpen, rootRef)

	useEffect(() => {
		// So that we can visually see which element is focused.
		setCurrentFocusIndex(
			options?.findIndex((option) => option.value === value) || 0
		)
	}, [isOpen])

	const currentValueName =
		options?.find((option) => option.value === value)?.name || null

	const focusOption = (index: number) => {
		const focusedElement = getOptionElement(index)

		if (focusedElement) {
			adjustDropdownScroll(focusedElement)
		}

		setCurrentFocusIndex(index)
		if (!isOpen && onChange) {
			onChange(options?.[index].value)
		}
	}

	/* Change dropdown scroll position if necessary in a way that
	 * the focused option is always visible.
	 */
	const adjustDropdownScroll = (focusedElement: HTMLButtonElement) => {
		// Focus element position lines.
		const focusedElementTopLine = focusedElement.offsetTop
		const focusedElementBottomLine =
			focusedElement.offsetTop + focusedElement.offsetHeight

		// Dropdown element scroll position lines.
		const dropdownScrollTopLine = dropdownRef.current.scrollTop
		const dropdownScrollBottomLine =
			dropdownRef.current.scrollTop + dropdownRef.current.offsetHeight

		// If element hidden on top.
		if (focusedElementTopLine < dropdownScrollTopLine) {
			dropdownRef.current.scrollTo({
				top: focusedElementTopLine
			})
		}
		// If element is hidden on bottom.
		else if (focusedElementBottomLine > dropdownScrollBottomLine) {
			dropdownRef.current.scrollTo({
				top: focusedElementBottomLine - dropdownRef.current.offsetHeight
			})
		}
	}

	/*
	 * Find the HTMLElement at index.
	 */
	const getOptionElement = (index: number): HTMLButtonElement => {
		const elements = Array.from(dropdownRef.current.children)
		if (index < 0 || index > elements.length - 1) return null
		return elements[index] as HTMLButtonElement
	}

	/*
	 * Handle navigation with keyboard.
	 */
	const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
		switch (e.key) {
			case 'ArrowUp': {
				e.preventDefault()
				const index = Math.max(0, currentFocusIndex - 1)
				focusOption(index)

				break
			}

			case 'ArrowDown': {
				e.preventDefault()
				const index = Math.min(options.length - 1, currentFocusIndex + 1)
				focusOption(index)
				break
			}

			case 'Enter': {
				if (onChange) onChange(options[currentFocusIndex].value)
				break
			}

			default:
				if (isOpen && e.key.length === 1) {
					const optionIndex = options.findIndex(({ name }) =>
						(name as string).toLowerCase().startsWith(e.key.toLowerCase())
					)
					if (optionIndex !== -1) {
						focusOption(optionIndex)
					}
				}
		}
	}

	return (
		<div
			className={`Select2 theme-${theme}${className ? ' ' + className : ''}`}
			ref={rootRef}
		>
			<button
				className={`${
					buttonElement?.className ? ` ${buttonElement.className}` : ''
				}`}
				ref={buttonRef}
				onClick={() => {
					setIsOpen(!isOpen)
				}}
				onKeyDown={handleKeyDown}
				style={buttonElement?.style}
			>
				{currentValueName || label}
			</button>
			{children}

			<div className={`dropdown${isOpen ? ' active' : ''}`} ref={dropdownRef}>
				{options?.map((option, i) => (
					<button
						className={`option${currentFocusIndex === i ? ' focused' : ''}${
							optionElement?.className ? ` ${optionElement.className}` : ''
						}${
							option.element?.className ? ` ${option.element?.className}` : ''
						}`}
						key={i}
						style={{
							...(optionElement?.style || {}),
							...(option.element?.style || {})
						}}
						onMouseDown={(e) => {
							// Then we don't loose focus on main button.
							e.preventDefault()
						}}
						onClick={() => {
							if (onChange) onChange(option.value)
							setIsOpen(false)
						}}
						onMouseMoveCapture={() => {
							setCurrentFocusIndex(i)
						}}
					>
						{option.name}
					</button>
				))}
			</div>
		</div>
	)
}

export default Select