/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'

import ReactDOM from 'react-dom'

import useIsMounted from '~hooks/useIsMounted'

import { TModalHook, TModalProps, TModalWithPayloalHook } from './Modal.type'

import './Modal.scss'

const ModalComponent: React.FC<TModalProps> = ({
	children,
	isOpen,
	close,
	theme = 'standard',
	style = {},
	className = '',
	allowQuitOnClickOverlay = false,
	showCloseButton = false,
	disableMainScroll = true
}) => {
	useEffect(() => {
		if (!disableMainScroll) return

		if (isOpen) {
			document.documentElement.classList.add('disable-scroll')
			return () => {
				document.documentElement.classList.remove('disable-scroll')
			}
		}
	}, [isOpen])

	useEffect(() => {
		const handleEscape = (e) => {
			if (e.keyCode === 27 && close) close()
		}

		document.addEventListener('keydown', handleEscape)

		return () => {
			document.removeEventListener('keydown', handleEscape)
		}
	}, [isOpen])

	// return empty fragment if modal not open
	if (!isOpen) return <></>
	return (
		<div
			className={`${className} super-modal ${
				isOpen ? 'active' : ''
			} theme-${theme}`}
		>
			{showCloseButton && (
				<CloseButton
					handleClose={() => {
						close()
					}}
				/>
			)}
			<div
				className="super-overlay"
				onClick={() => {
					if (allowQuitOnClickOverlay) close()
				}}
			></div>

			<div className="modal-content" style={style}>
				{children}
			</div>
		</div>
	)
}

export const useModal = (): TModalHook => {
	const [isOpen, setIsOpen] = useState(false)

	const close = () => {
		setIsOpen(false)
	}

	const open = () => {
		setIsOpen(true)
	}

	return {
		isOpen,
		setIsOpen,
		close,
		open
	}
}

export function useModalWithPayload<T>(): TModalWithPayloalHook<T> {
	const [data, setData] = useState({
		isOpen: false,
		payload: null
	})

	const isMounted = useIsMounted()

	const openWithPayload = (payload) => {
		if (!isMounted()) return
		setData({
			isOpen: true,
			payload
		})
	}

	const close = () => {
		if (!isMounted()) return
		setData({
			isOpen: false,
			payload: null
		})
	}

	const open = (payload) => {
		if (!isMounted()) return
		setData({
			isOpen: true,
			payload
		})
	}

	return {
		isOpen: data.isOpen,
		openWithPayload,
		close,
		open,
		payload: data.payload
	}
}

const CloseButton = ({ handleClose }) => (
	<button className="CloseButton" onClick={handleClose}>
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.1485 5.85164C26.3449 -1.95055 13.6539 -1.95055 5.85023 5.85164C-1.95008 13.6538 -1.95008 26.3483 5.85023 34.1505C9.75207 38.0508 14.8761 40 20.0002 40C25.1244 40 30.2467 38.0507 34.1485 34.1505C41.9506 26.3484 41.9506 13.6538 34.1485 5.85164ZM28.2524 25.8965C28.9044 26.5484 28.9044 27.6023 28.2524 28.2542C27.9273 28.5794 27.5004 28.7428 27.0735 28.7428C26.6467 28.7428 26.2197 28.5794 25.8946 28.2542L20.0002 22.358L14.1074 28.2525C13.7806 28.5777 13.3537 28.7411 12.9285 28.7411C12.5016 28.7411 12.0747 28.5777 11.7496 28.2525C11.0976 27.6006 11.0976 26.5451 11.7496 25.8947L17.6423 20.0002L11.7479 14.1057C11.096 13.4537 11.096 12.3982 11.7479 11.7479C12.3982 11.0959 13.4537 11.0959 14.1057 11.7479L20.0001 17.6424L25.8945 11.7479C26.5464 11.0959 27.6003 11.0959 28.2523 11.7479C28.9042 12.3982 28.9042 13.4537 28.2523 14.1057L22.3578 20.0002L28.2524 25.8965Z"
				fill="white"
			/>
		</svg>
	</button>
)

const Modal: React.FC<TModalProps> = (props) =>
	props.portal ? (
		ReactDOM.createPortal(
			<ModalComponent {...props} />,
			document.getElementById(props.portalRootId || 'modal-root')
		)
	) : (
		<ModalComponent {...props} />
	)
export default Modal

export type TModalComponent<T = any> = React.FC<TModalProps & T>
