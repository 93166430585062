import React, { useRef, useState } from 'react'

import { TButtonProps } from './Button.types'

import './Button.scss'

const Button = ({
	children,
	onClick,
	theme = 'normal',
	style = {},
	className = '',
	rounded = false,
	disabled,
	effect = 'ripple',
	rippleEffectParam = {},
	textAlign = 'left',
	onMouseDown
}: TButtonProps) => {
	const rippleContainerRef = useRef<HTMLDivElement>(null)
	const [ripples, setRipples] = useState([])
	const [mouseDown, setMouseDown] = useState(false)
	const [mouseDownRippleStyle, setMouseDownRippleStyle] = useState({})

	const handleClickRippleEffect = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		const rect = rippleContainerRef.current.getBoundingClientRect()

		const x = rippleEffectParam.startFromCenter
			? rippleContainerRef.current.offsetWidth / 2
			: e.clientX - rect.x
		const y = rippleEffectParam.startFromCenter
			? rippleContainerRef.current.offsetHeight / 2
			: e.clientY - rect.y

		const rippleSize = rippleEffectParam.rippleSize || 300

		setRipples([
			...ripples.filter(({ dt }) => new Date().getTime() - dt < 2000),
			{
				id: Math.round(Math.random() * 100000000),
				style: {
					top: y - rippleSize / 2,
					left: x - rippleSize / 2,
					width: rippleSize,
					height: rippleSize
				},
				dt: new Date().getTime()
			}
		])
	}

	const handleClickMiddleware = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		if (effect === 'ripple') handleClickRippleEffect(e)

		if (onClick) return onClick(e)
	}

	return (
		<button
			disabled={disabled}
			style={style}
			className={`Button theme-${theme} ${
				rounded ? 'rounded' : ''
			} effect-${effect} text-align-${textAlign} ${className}`}
			onClick={handleClickMiddleware}
			onMouseDown={onMouseDown}
		>
			{effect === 'ripple' && (
				<div
					className="ripple-container"
					ref={rippleContainerRef}
					onMouseDown={(e) => {
						const rect = rippleContainerRef.current.getBoundingClientRect()

						const x = e.clientX - rect.x - 100
						const y = e.clientY - rect.y - 100

						setMouseDownRippleStyle({
							left: x,
							top: y
						})

						setMouseDown(true)
					}}
					onMouseUp={() => {
						setMouseDown(false)
					}}
					onBlur={() => {
						setMouseDown(false)
					}}
					style={{
						opacity: rippleEffectParam.rippleOpacity
							? rippleEffectParam.rippleOpacity
							: 0.1
					}}
				>
					{ripples.map(({ id, style }) => (
						<span className="ripple" style={style} key={id} />
					))}
					<span
						className={`mouse-down-ripple ${mouseDown ? 'active' : ''}`}
						style={mouseDownRippleStyle}
					/>
				</div>
			)}

			<div className="content">{children}</div>
		</button>
	)
}
export default Button
