import React from 'react'
import { TConfirmationModalPayload } from '../ConfirmationModal.types'

import './ConfirmationModalContent.scss'

const ConfirmationModalContent = ({
	payload,
	closeModal
}: {
	payload: TConfirmationModalPayload
	closeModal: () => void
}) => {
	return (
		<div className={`ConfirmationModalContent theme-${payload.theme}`}>
			<header>
				<h2>{payload.title}</h2>
			</header>
			<p>{payload.description}</p>

			<div className="buttons">
				<button
					className="refuse"
					onClick={() => {
						closeModal()
						payload.refuseCallback?.()
					}}
				>
					{payload.refuseText}
				</button>
				<button
					className="accept"
					onClick={() => {
						closeModal()
						payload.acceptCallback()
					}}
				>
					{payload.acceptText}
				</button>
			</div>
		</div>
	)
}

export default ConfirmationModalContent
