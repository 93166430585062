import React, { useState } from 'react'

import Modal from '../Modal'

import {
	TConfirmationModalData,
	TUseConfirmationModalContext
} from './ConfirmationModal.types'
import ConfirmationModalContent from './ConfirmationModalContent/ConfirmationModalContent'

import './ConfirmationModal.scss'

const confirmationModalContext = React.createContext(null)

export const ConfirmationModalProvider: TConfirmationModalProvider = ({
	children
}) => {
	const [data, setData] = useState<TConfirmationModalData>({
		isOpen: false,
		payload: null
	})

	const open = (payload) => {
		setData({
			isOpen: true,
			payload
		})
	}
	const close = () => {
		setData({
			isOpen: false,
			payload: null
		})
	}

	return (
		<confirmationModalContext.Provider
			value={{
				open,
				close
			}}
		>
			<Modal
				isOpen={data.isOpen}
				close={close}
				showCloseButton={true}
				className="ConfirmationModal"
			>
				{data.isOpen && (
					<ConfirmationModalContent
						closeModal={close}
						payload={{
							acceptText: 'Oui',
							refuseText: 'Non',
							theme: 'normal',
							...data.payload
						}}
					/>
				)}
			</Modal>
			{children}
		</confirmationModalContext.Provider>
	)
}

type TConfirmationModalProvider = React.FC

export function useConfirmationModalContext() {
	return React.useContext<TUseConfirmationModalContext>(
		confirmationModalContext
	)
}
